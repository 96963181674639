<template>
  <el-dialog :visible.sync="visible" width="90%" title="选择菜单模板" class="dialog_box">
    <div class="template_list">
      <template v-if="templateList.length">
        <div
          v-for="item in templateList"
          :key="item.id"
          class="template_box"
          :class="{cur:item.id===currentTemplate.id}"
          @click="selectTemplate(item)"
        >
          <img :src="item.cover + '&imageView2/2/w/256/h/256'" class="img" alt="">
          <p style="height: 30px;line-height: 30px">
            <span class="text_info">{{ item.name }}</span>
            <span class="show_img" title="点击查看效果图" @click.stop="showBigImg(item)">预览</span>
          </p>
          <div class="selected_box"><i class="el-icon-check"></i></div>
        </div>
      </template>
      <div v-else class="empty_box">暂无内容</div>
    </div>
    <div slot="footer">
      <el-button type="primary" :loading="saveLoading" @click="submitData">保 存</el-button>
      <el-button @click="() => { this.visible = false }">取 消</el-button>
    </div>
    <BigImg ref="bigImg"></BigImg>
  </el-dialog>
</template>

<script>
import BigImg from './BigImg'

export default {
  name: 'MenuType',
  components: { BigImg },
  data() {
    return {
      visible: false,
      saveLoading: false,
      condition: {},
      templateList: [],
      currentTemplate: {}
    }
  },
  methods: {
    show() {
      this.visible = true
      this.condition = {}
      this.currentTemplate = {}
      this.getTemplateList()
    },
    setConditionData({ type, value }) {
      if (this.condition[type]) {
        this.condition[type] = value
      } else {
        this.$set(this.condition, type, value)
      }
      this.getTemplateList()
    },
    async getTemplateList() {
      const response = await this.$axios.get('/athena/template/list', { type: 'menu' })
      this.templateList = response.data
    },
    selectTemplate(data) {
      this.currentTemplate = data
    },
    async submitData() {
      if (!this.currentTemplate.id) {
        this.$message.warning('请选择模版')
        return false
      }
      const { id: templateId } = this.currentTemplate
      this.saveLoading = true
      const response = await this.$axios.post('/athena/menu/setTemplate', { templateId })
      this.saveLoading = false
      if (response.code === '0000') {
        this.$message.success('保存成功')
        this.visible = false
        this.$emit('submit')
      }
    },
    showBigImg(data) {
      this.$refs.bigImg.show(data.cover)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog_box {
  ::v-deep .el-dialog {
    margin-top: 50px !important;
    height: calc(100% - 100px);
    .el-dialog__header {
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;
    }
    .el-dialog__body {
      padding: 0;
      height: calc(100% - 108px);
    }
    .el-dialog__footer {
      padding: 10px;
      border-top: 1px solid #ccc;
    }
  }
}

.color_box {
  @include clear-float;
  span {
    float: left;
    width: 24px;
    height: 24px;
    margin: 0 6px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    transition: all .5s;
    &.cur {
      border-radius: 0;
      border: 1px solid hsla(0, 0%, 60%, .2);
    }
  }
}

.main_box {
  @include clear-float;
  height: 100%;
  .search_condition {
    width: 280px;
    height: 100%;
    overflow: auto;
    float: left;
    border-right: 1px solid #ccc;
    .search_item {
      .title {
        text-align: center;
        border-bottom: 1px solid #ccc;
        line-height: 40px;
        height: 40px;
        font-size: 18px;
      }
      .list_box {
        padding: 10px 16px;
        border-bottom: 1px solid #ccc;
        @include clear-float;
        span {
          font-size: 12px;
          float: left;
          margin: 4px;
          border: 1px solid #dcdfe6;
          padding: 4px 8px;
          cursor: pointer;
          border-radius: 2px;
          &.cur {
            color: #409eff;
            border-color: #409eff;
          }
        }
      }
    }
  }
}
.template_list {
  float: left;
  height: 100%;
  padding: 10px;
  overflow: auto;
  @include clear-float;
  .template_box {
    float: left;
    position: relative;
    width: 320px;
    height: 220px;
    margin: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .0666667), 0 4px 16px 0 rgba(0, 0, 0, .0666667);
    p {
      padding-left: 6px;
      color: #757575;
      white-space: nowrap;
    }
    .selected_box {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-color: #13ce66 #13ce66 transparent transparent;
      border-style: solid;
      border-width: 20px;
      color: #fff;
      i {
        position: absolute;
        top: -14px;
        right: -16px;
        font-size: 16px;
      }
    }
    &.cur {
      .selected_box {
        display: block;
      }
      top: -2px;
    }
    &:hover {
      .show_img {
        display: block;
      }
    }
    .img {
      width: 318px;
      height: 188px;
      cursor: pointer;
    }
    .text_info {
      @include text-hidden;
      width: 240px;
    }
    .show_img {
      float: right;
      display: none;
      transition: all .3s linear;
      color: #409eff;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .empty_box {
    height: 100%;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
  }
}
</style>
