<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    class="dialog_main"
    width="1340px"
    title="预览大图"
    @close="cancelDialog"
  >
    <div class="big-img-content">
      <span class="img-icon img-icon-left" @click="changeImg('left')"><i class="el-icon-arrow-left" /></span>
      <img :src="`${currentUrl}&imageView2/2/w/1000/h/1000`" class="image" alt="">
      <span class="img-icon img-icon-right" @click="changeImg('right')"><i class="el-icon-arrow-right" /></span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'BigImg',
  data() {
    return {
      visible: false,
      currentUrl: '',
      allSrc: [],
      currentIndex: 0
    }
  },
  methods: {
    cancelDialog() {
      this.allSrc = []
      this.currentIndex = 0
      this.currentUrl = ''
    },
    show(imgList) {
      this.allSrc = imgList
      this.currentUrl = this.allSrc[this.currentIndex]
      this.visible = true
    },
    changeImg(type) {
      let currentIndex = this.currentIndex
      if (type === 'right') {
        currentIndex = currentIndex === this.allSrc.length - 1 ? 0 : currentIndex + 1
      } else if (type === 'left') {
        currentIndex = currentIndex === 0 ? this.allSrc.length - 1 : currentIndex - 1
      }
      this.currentIndex = currentIndex
      this.currentUrl = this.allSrc[this.currentIndex]
    }
  }
}
</script>

<style scoped lang="scss">
.dialog_main {
  ::v-deep .el-dialog {
    margin: 20px auto !important;
    .el-dialog__header {
      padding: 10px;
      .el-dialog__title{
        font-size: 14px;
        font-weight: bold;
      }
      .el-dialog__headerbtn{
        font-size: 20px;
        top: 10px;
      }
    }
    .el-dialog__headerbtn {
      top: 12px;
    }
    .el-dialog__body {
      padding: 0;
      height: calc(100% - 108px);
    }
  }
  .big-img-content{
    position: relative;
    .img-icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: rgba(0, 0, 0, .6);
      text-align: center;
      line-height: 48px;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      cursor: pointer;
      &:hover{
        box-shadow: $cardShadow;
      }
    }
    .img-icon-left{
      left: 20px;
    }
    .img-icon-right{
      right: 20px;
    }
    .image {
      width: 100%;
      min-height: 200px;
      vertical-align: bottom;
    }
  }
}
</style>
